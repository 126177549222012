import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import useApiCall from '~/hooks/useApiCall';

import GlobalStyle from './styled';
import { useToast } from '~/components/toast';
import Scrollbar from '~/components/scrollbar2';

import Header from 'web~/components/header';
import Footer from 'web~/components/footer';
import PopupHub from 'web~/components/popup/hub';

import useCheck from './useCheck';
import PublicRoute from './publicRoute';
import PrivateRoute from './privateRoute';

const Home = lazy(() => import('../views/home'));                                       // 홈
const BusinessInfo = lazy(() => import('../views/business-info'));              // 사업자 확인
const Login = lazy(() => import('../views/login'));                                     // 로그인
const FindPassword = lazy(() => import('../views/findPassword'));                       // 비밀번호 찾기
const Join = lazy(() => import('../views/join'));                             // 회원가입
const CustomService = lazy(() => import('../views/customService'));                     // 고객센터
const Intro = lazy(() => import('../views/intro'));                                     // 소개
const JoinConsult = lazy(() => import('../views/join-consult'));                     // 가입 및 광고문의 페이지

const Dashboard = lazy(() => import('../views/dashboard'));                             // 대시보드
const Room = lazy(() => import('../views/room'));                                       // 매물 관리
const Form = lazy(() => import('../views/form'));                                     // 매물 등록
const GongsilCenter = lazy(() => import('../views/gongsil'));                   // 공실센터
const Product = lazy(() => import('../views/product'));                                 // 상품 관리
const Account = lazy(() => import('../views/account'));                                 // 계정 관리
const Consult = lazy(() => import('../views/consult'));                                 // 광고 문의
const Inquiry = lazy(() => import('../views/inquiry'));                               // 문의 내역
const Sign = lazy(() => import('../views/sign'));                                       // 비대면 계약

function Check() {
  useCheck();
  return null;
}

function Routes() {
  const dispatch = useDispatch();
  const toast = useToast();
  const call = useApiCall();

  useEffect(() => {
    dispatch.call.init(call);
    dispatch.toast.init(toast);
  }, []);

  return (
    <>
      <Scrollbar />

      <div id="content">
        <GlobalStyle />
        <Check />

        <PopupHub />

        <Header />

        <Suspense fallback={<div></div>}>
          <Switch>
            {/* 홈 */}
            <PublicRoute exact path="/">
              <Home />
            </PublicRoute>

            {/* 로그인 */}
            <PublicRoute path="/login">
              <Login />
            </PublicRoute>

            {/* 비밀번호 찾기 */}
            <PublicRoute path="/find-password">
              <FindPassword />
            </PublicRoute>

            {/* 회원가입 */}
            <PublicRoute path="/join">
              <Join />
            </PublicRoute>

            {/* 광고 문의 */}
            <PublicRoute path="/consult">
              <Consult />
            </PublicRoute>

            {/* 가입 및 광고문의 */}
            <PublicRoute path="/join-consult">
              <JoinConsult />
            </PublicRoute>

            {/* 소개 */}
            <Route path="/intro/:type?">
              <Intro />
            </Route>

            {/* 고객센터 */}
            <Route path="/service">
              <CustomService />
            </Route>

            {/* 사업자 확인 */}
            <PrivateRoute path="/business-info">
              <BusinessInfo />
            </PrivateRoute>

            {/* 대시보드 */}
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>

            {/* 매물관리 */}
            <PrivateRoute path="/room">
              <Room />
            </PrivateRoute>

            {/* 매물 등록 */}
            <PrivateRoute path="/form">
              <Form />
            </PrivateRoute>

            {/* 공실 리스트 */}
            <PrivateRoute path="/gongsil">
              <GongsilCenter />
            </PrivateRoute>

            {/* 상품 관리 */}
            <PrivateRoute path="/product">
              <Product />
            </PrivateRoute>

            {/* 계정 관리 */}
            <PrivateRoute path="/account">
              <Account />
            </PrivateRoute>

            {/* 문의 내역 */}
            <PrivateRoute path="/inquiry/:tab(talk|sms)?">
              <Inquiry />
            </PrivateRoute>

            {/* 현장촬영 관리, 계약 관리 */}
            <PrivateRoute path="/sign">
              <Sign />
            </PrivateRoute>

            <Redirect to="/" />
          </Switch>
        </Suspense>

        <Footer />
      </div>
    </>
  );
}

export default Routes;
