import { useRef, useEffect } from 'react';
import axios, { CancelToken } from 'axios';
import Cookie from 'js-cookie';
import qs from '~/utils/query-string';

import deleteBlank from '~/utils/deleteBlank';
import bridge from '~/utils/bridge';

const VERSION = 1;

let from = null;

function useApiCall() {
  const _apiCall = useRef(null);
  const _source = useRef(CancelToken.source());

  useEffect(() => {
    const apiCall = axios.create({
      headers: {
        csrf: 'token',
        Accept: 'application/json, text/plain, */*',
        Expires: -1,
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'App-Platform': 'web',
        'App-Version': VERSION,
        'Content-Type': 'application/json',
      },
      cancelToken: _source.current.token,
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params);
        }
      },
    });

    apiCall.interceptors.request.use(reqConfig);
    apiCall.interceptors.response.use(resSuccess, resError);

    _apiCall.current = apiCall;

    return () => {
      _source.current.cancel('Operation canceled by the user.');
    };
  }, []);

  function resSuccess(response) {
    return response;
  }

  function resError(error) {
    let err;

    if (axios.isCancel(error)) {
      console.log('API 요청이 취소되었습니다.', from, error);
      const f = from;
      from = null;
      return Promise.reject({ msg: '', from: f }, 'canceled');
    }

    try {
      err = error.response.data;
    } catch (e) {
      err = error;
    }

    switch (error.response.status) {
      case 400:
        // 해당 응답 발생시 약관 동의 프로세스 처리 (기존 강제약관 동의)
        if (err?.code === 410) {
          if (window.location.pathname !== '/agreement') {
            window.location.href = '/agreement';
          }

        // 매물광고재등록시 해당 응답 발생시 매물수정페이지로 이동 (해당 api요청하는 곳에서 처리)
        } else if (err?.code === 412) {

        // 해당 응답 발생시 페이지 리프레쉬 (웹에서만) // 버전이 다름 새로고침
        } else if (err?.code === 412) {
          return window.location.reload();

        } else if (err?.code === 416) {
          bridge.android(call => {
            call.error(JSON.stringify({ code: 416 }));
          });

          bridge.ios({
            cmd: 'error',
            message: {
              code: 416,
            },
          });

          if (window.location.pathname !== '/emergency') {
            window.location.href = '/emergency';
          }

        } else if (err?.code === 417) { // 대표자(417) 인증 에러 코드 // 417로 통합
          if (window.location.pathname !== '/agreement' && window.location.pathname !== '/business-info') {
            window.location.href = '/business-info';
          }
        }

        break;

      case 401: // 해당 응답 발생시 로그아웃 처리
        bridge.android(call => {
          call.error(JSON.stringify({ code: 401 }));
        });

        bridge.ios(call => {
          call.callHandler('iOS Echo', {
            cmd: 'error',
            message: {
              code: 401,
            }
          });
        });

        bridge.web(() => {
          logout(err?.msg ?? '잘못된 접근입니다.');
        });
        return;

      case 500:
        if (typeof err === 'string') {
          err = { msg: err };
        } else if (err?.message) {
          err = { msg: err?.message ?? '서버에러가 발생하였습니다.' };
        } else if (err?.msg) {
          err = { ...err };
        } else {
          err = { msg: '서버에러가 발생하였습니다.' };
        }
        break;

      default:
        if (!err?.msg) {
          err = { msg: '서버에러가 발생하였습니다.' };
        }
    }

    from = null;
    return Promise.reject(err, error.response.status);
  }

  async function logout(msg) {
    if (msg !== '로그인이 필요합니다.') {
      try {
        await post('/api/v2/user/logout');

        alert(msg);

        if (window.location.pathname === '/' || window.location.pathname === '/login') return;
        window.location.href = '/';

      } catch(err) {
        console.log(err);
      }
    }
  }

  function reqConfig(config) {
    if (['post', 'put', 'patch'].includes(config.method)) {
      config.data = deleteBlank(config.data);

    } else if (config.method === 'get') {
      config.params = deleteBlank(config.params);
    }

    return config;
  }

  function checkToken() {
    const safeAuthConfirm = Cookie.get('safe_auth_confirm');
    let headers = {};

    if (safeAuthConfirm) {
      headers = {
        'Safe-Auth-Confirm': safeAuthConfirm
      };
    }

    return headers;
  }

  function get(url, params = {}) {
    from = url;

    return _apiCall.current
      .request({
        method: 'GET',
        url,
        params,
        responseType: 'json',
        data: null // server 에서 `content-type: application/json`으로 받고 있는데 data property 없으면 `content-type`을 지우는 이슈 때문에 추가
      })
      .then(response => {
        from = null;
        return Promise.resolve(response.data.result);
      });
  }

  function post(url, data = {}, config) {
    from = url;

    return _apiCall.current
      .request({
        method: 'POST',
        url,
        data,
        cancelToken: config?.cancel ? new axios.CancelToken(config.cancel) : undefined,
        headers: checkToken(),
        responseType: 'json',
      })
      .then(response => {
        from = null;
        return Promise.resolve(response.data.result);
      });
  }

  return { get, post };
}

export default useApiCall;
