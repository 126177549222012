const NORMAL_PACKAGE = [
  { month: 1, day: 30, normal: 10, plus30: 2, plus1: 10, discount: 0 },
  { month: 2, day: 60, normal: 10, plus30: 5, plus1: 15, discount: 0 },
  { month: 3, day: 90, normal: 10, plus30: 5, plus1: 20, discount: 5 },
  { month: 6, day: 180, normal: 10, plus30: 10, plus1: 50, discount: 10 },
  { month: 12, day: 360, normal: 10, plus30: 10, plus1: 100, discount: 20 },
];

const OFFICETEL_PACKAGE_HALF = [
  { month: 1, day: 30, officetel: 5, plus30: 2, plus1: 10, discount: 0 },
  { month: 2, day: 60, officetel: 5, plus30: 3, plus1: 15, discount: 0 },
  { month: 3, day: 90, officetel: 5, plus30: 3, plus1: 20, discount: 5 },
  { month: 6, day: 180, officetel: 5, plus30: 5, plus1: 50, discount: 10 },
  { month: 12, day: 360, officetel: 5, plus30: 5, plus1: 100, discount: 20 },
];

/**
 * TODO: discount 값 변경해야함
 */
const OFFICETEL_PACKAGE = [
  { month: 1, day: 30, officetel: 10, plus30: 2, plus1: 10, discount: 0 },
  { month: 2, day: 60, officetel: 10, plus30: 5, plus1: 15, discount: 0 },
  { month: 3, day: 90, officetel: 10, plus30: 5, plus1: 20, discount: 5 },
  { month: 6, day: 180, officetel: 10, plus30: 10, plus1: 50, discount: 10 },
  { month: 12, day: 360, officetel: 10, plus30: 10, plus1: 100, discount: 20 },
];

const ALL_IN_ONE_PACKAGE_TEN = [
  { month: 1, day: 30, allInOne: 10, plus30: 2, discount: 0 },
  { month: 2, day: 60, allInOne: 10, plus30: 2, discount: 0 },
  { month: 3, day: 90, allInOne: 10, plus30: 2, discount: 5 },
  { month: 6, day: 180, allInOne: 10, plus30: 2, discount: 10 },
  { month: 12, day: 360, allInOne: 10, plus30: 2, discount: 10 },
  { month: 1, day: 'regular_30', allInOne: 10, plus30: 2, discount: 0 },
  { month: 3, day: 'regular_90', allInOne: 10, plus30: 2, discount: 5 },
  { month: 6, day: 'regular_180', allInOne: 10, plus30: 2, discount: 10 },
];

const ALL_IN_ONE_PACKAGE_FIVE = [
  { month: 1, day: 30, allInOne: 5, plus30: 2, discount: 0 },
  { month: 2, day: 60, allInOne: 5, plus30: 2, discount: 0 },
  { month: 3, day: 90, allInOne: 5, plus30: 2, discount: 5 },
  { month: 6, day: 180, allInOne: 5, plus30: 2, discount: 10 },
  { month: 12, day: 360, allInOne: 5, plus30: 2, discount: 10 },
  { month: 1, day: 'regular_30', allInOne: 5, plus30: 2, discount: 0 },
  { month: 3, day: 'regular_90', allInOne: 5, plus30: 2, discount: 5 },
  { month: 6, day: 'regular_180', allInOne: 5, plus30: 2, discount: 10 },
];

export function getPackageProductInfo(type, day) {
  switch(+type) {
    case 20:
    case 25:
      return NORMAL_PACKAGE.find(i => i.day === +day);

    case 21:
      return OFFICETEL_PACKAGE_HALF.find(i => i.day === +day);

    case 24:
      return OFFICETEL_PACKAGE.find(i => i.day === +day);
    
    case 26:
      return ALL_IN_ONE_PACKAGE_TEN.find(i => i.day === +day);
    
    case 27:
      return ALL_IN_ONE_PACKAGE_FIVE.find(i => i.day === +day);

    default:
      return {};
  }
};

/**
 * @description 정기결제 regular_ 제거 후 정기결제 여부, 상품 기간만 추출
 */
export function getProductInfo(inputDay = '0') {
  const day = parseInt(String(inputDay).match(/\d+/), 10) || 0;
  const isRegularPayment = String(inputDay).includes('regular_');

  return {
    isRegularPayment,
    day,
  };
}


export function getAllInOnePackageProductInfo({
  type,
  day,
  isRegularPayment = false,
}) {
  switch(+type) {
    case 26: {
      if (isRegularPayment) return ALL_IN_ONE_PACKAGE_TEN.find(i => i.day === day);
      return ALL_IN_ONE_PACKAGE_TEN.find(i => i.day === +day);
    }

    case 27: {
      if (isRegularPayment) return ALL_IN_ONE_PACKAGE_FIVE.find(i => i.day === day);
      return ALL_IN_ONE_PACKAGE_FIVE.find(i => i.day === +day);
    }

    default:
      return {};
  }
};
