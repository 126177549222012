import { css } from 'styled-components';

import type { TChipColor } from '../../types';

export function getLightLineColor(color: TChipColor) {
  switch (color) {
    case 'gray':
      return css`
        color: ${({ theme }) => theme.color.gray[900]};
        border-color: ${({ theme }) => theme.color.gray[400]};
        background-color: #fff;
        
        > button > svg {
          circle[fill],
          ellipse[fill],
          line[fill],
          path[fill],
          polygon[fill],
          polyline[fill],
          rect[fill] {
            fill: ${({ theme }) => theme.color.gray[900]} !important;
          }

          circle[stroke],
          ellipse[stroke],
          line[stroke],
          path[stroke],
          polygon[stroke],
          polyline[stroke],
          rect[stroke] {
            stroke: ${({ theme }) => theme.color.gray[900]} !important;
          }
        }
      `;

    case 'blue':
      return css`
        color: ${({ theme }) => theme.color.blue[500]};
        border-color: ${({ theme }) => theme.color.blue[100]};
        background-color: #fff;
        
        > button > svg {
          circle[fill],
          ellipse[fill],
          line[fill],
          path[fill],
          polygon[fill],
          polyline[fill],
          rect[fill] {
            fill: ${({ theme }) => theme.color.blue[500]} !important;
          }

          circle[stroke],
          ellipse[stroke],
          line[stroke],
          path[stroke],
          polygon[stroke],
          polyline[stroke],
          rect[stroke] {
            stroke: ${({ theme }) => theme.color.blue[500]} !important;
          }
        }
      `;

    case 'red':
      return css`
        color: ${({ theme }) => theme.color.red[500]};
        border-color: ${({ theme }) => theme.color.red[100]};
        background-color: #fff;

        > button > svg {
          circle[fill],
          ellipse[fill],
          line[fill],
          path[fill],
          polygon[fill],
          polyline[fill],
          rect[fill] {
            fill: ${({ theme }) => theme.color.red[500]} !important;
          }

          circle[stroke],
          ellipse[stroke],
          line[stroke],
          path[stroke],
          polygon[stroke],
          polyline[stroke],
          rect[stroke] {
            stroke: ${({ theme }) => theme.color.red[500]} !important;
          }
        }
      `;

    case 'green':
      return css`
        color: ${({ theme }) => theme.color.green[500]};
        border-color: ${({ theme }) => theme.color.green[100]};
        background-color: #fff;

        > button > svg {
          circle[fill],
          ellipse[fill],
          line[fill],
          path[fill],
          polygon[fill],
          polyline[fill],
          rect[fill] {
            fill: ${({ theme }) => theme.color.green[500]} !important;
          }

          circle[stroke],
          ellipse[stroke],
          line[stroke],
          path[stroke],
          polygon[stroke],
          polyline[stroke],
          rect[stroke] {
            stroke: ${({ theme }) => theme.color.green[500]} !important;
          }
        }
      `;

    case 'pink':
      return css`
        color: ${({ theme }) => theme.color.pink[500]};
        border-color: ${({ theme }) => theme.color.pink[100]};
        background-color: #fff;

        > button > svg {
          circle[fill],
          ellipse[fill],
          line[fill],
          path[fill],
          polygon[fill],
          polyline[fill],
          rect[fill] {
            fill: ${({ theme }) => theme.color.pink[500]} !important;
          }

          circle[stroke],
          ellipse[stroke],
          line[stroke],
          path[stroke],
          polygon[stroke],
          polyline[stroke],
          rect[stroke] {
            stroke: ${({ theme }) => theme.color.pink[500]} !important;
          }
        }
      `;

    default:
      return '';
  }
}
