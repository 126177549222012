import { createModel } from '@rematch/core';
import { produce } from 'immer';

import productType from '~/enum/productType';

import state from './state';

import { addProduct, renewProduct } from './utils';
import { getProductInfo } from '~/utils/packageProduct';

function scrollToTop() {
  document.documentElement.scrollTop = 0;
}

function scrollTo(idx) {
  const isMobile = window.location.pathname.indexOf('/mobile') > -1;

  if (isMobile) {
    scrollToTop();

  } else {
    const dom = document.getElementById(`item_${idx}`);
    if (dom) {
      dom.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

    } else {
      document.documentElement.scrollTop = 0;
    }
  }

}

const purchase = createModel({
  state,
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    package(state, { newItem }) {
      return {
        ...state,
        items: produce(state.items, draft => {
          draft[draft.findIndex(d => d.type.indexOf('package_') !== -1)] = newItem;
        }),
        isLoading: false,
      };
    },
    modalOpen(state, payload = '') {
      return {
        ...state,
        isOpen: payload,
        isLoading: false,
      };
    },
    reset() {
      return state;
    },
  },
  effects: dispatch => ({
    /**
     * 상품 추가
     * @param {Array} types 추가할 상품 타입들
     */
    addProduct(types, { purchase: { items } }) {
      const newItems = addProduct(items, types);

      if (newItems.length > 0) {
        this.update({
          items: [...items, ...newItems],
          isLoading: false,
        });
        this.modalOpen('');

        const idx = items.length;
        scrollTo(idx);
      }
    },

    /**
     * 패키지 상품 추가
     * @param {Array} types 패키지 상품 타입
     * @param {number} day 기간
     */
    addPackageProduct({ types, day }, { purchase: { items } }) {
      const { day: periodTo, isRegularPayment } = getProductInfo(day);
      const newItems = addProduct(items, types, { periodTo, isRegularPayment });

      if (newItems.length > 0) {
        this.update({
          items: [...items, ...newItems],
          isLoading: false,
        });
        this.modalOpen('');

        const idx = items.length;
        scrollTo(idx);
      }
    },

    /**
     * 이 지역 부동산 상품 추가
     * @param {Array} types 이 지역 부동산 상품 타입
     * @param {Object} address 이 지역 부동상 상품에 사용되는 주소정보
     */
    addAgentProduct({ types, address }, { purchase: { items } }) {
      const newItems = addProduct(items, types, address);

      if (newItems.length > 0) {
        this.update({
          items: [...items, ...newItems],
          isLoading: false,
        });
        this.modalOpen('');

        const idx = items.length;
        scrollTo(idx);
      }
    },

    /**
     * 연장 상품 생성
     * @param {*} renewItems 연장할 상품 정보
     */
    async renewProduct({ renewItems }, { call: { get }, purchase: { items } }) {
      // renewProduct는 Promise
      const promises = renewProduct(renewItems, get);
      const newItems = await Promise.all(promises);

      this.update({
        items: [...items, ...newItems],
        isLoading: false,
        isLoadingRenew: false,
      });

      const idx = items.length;
      scrollTo(idx);
    },

    /**
     * 상품에 가격정보를 업데이트 할때 사용
     * @param {Number} idx 갈아치울 상품의 위치
     * @param {Object} nextItem 갈아치울 상품 값.
     */
    updateItem({ idx, nextItem }, { purchase: { items } }) {
      const newItems = produce(items, draft => {
        draft[idx] = nextItem;
      });
      this.update({ items: newItems });
    },

    /**
     * 선택한 동 추천 정보를 아이템에 넣어주는 부분
     * @param {Number} idx 선택한 상품의 위치
     * @param {Object} item 선택한 상품의 정보
     */
    updateRegionRecommend({ idx, item }, { purchase: { items } }) {
      const newItems = produce(items, draft => {
        draft[idx]['region'] = item;
      });
      this.update({ items: newItems });
    },

    /**
     * 선택한 프리미엄의 정보를 아이템에 넣어주는 부분
     * @param {Number} idx 선택한 상품의 위치
     * @param {String} type 선택한 상품의 타입값 (상품정보 안에 선택한 정보를 저장하는 값의 키로 타입값을 사용함)
     * @param {Object} item 선택한 상품의 정보
     */
    updatePremium({ idx, type, item }, { purchase: { items } }) {
      const newItems = produce(items, draft => {
        draft[idx][type] = item;
      });
      this.update({ items: newItems });
    },

    /**
     * 상품결제에서 이미 패키지를 구매하기 눌렀을때 다른 패키지를 누르면 바꾼다.
     * @param {Number} idx 현재 추가되어있는 패키지의 위치
     * @param {String} type 변경할 패키지의 타입
     */
    switchPackage({ idx, type }, { purchase: { items } }) {
      const newItems = addProduct(items, [type]);
      this.package({ newItem: newItems[0] });
      scrollTo(idx);
    },

    /**
     * 전체 상품을 제거한다.
     */
    removeAll() {
      this.update({ items: [], isLoading: false });
    },

    /**
     * 추가한 상품을 제거하는데 사용
     * @param {Number} idx 제거할 상품의 위치
     */
    removeItem({ idx }, { purchase: { items } }) {
      const newItems = produce(items, draft => {
        draft.splice(idx, 1);
      });

      const index = newItems.length - 1;
      if (index >= 0) {
        const i = idx - 1;
        scrollTo(i > -1 ? i : 0);
      } else {
        scrollToTop();
      }

      this.update({ items: newItems });
    },

    /**
     * (오피스텔 || 아파트) 패키지 -> 다른패키지로 변경할때
     * 추가되어있는 단지상품이 있을경우 제거한다.
     * @param {Number} type 제거할 단지상품의 타입
     */
    removeComplex({ type }, { purchase: { items } }) {
      const newItems = items.reduce((result, product) => {
        if (product.type === type) return result;
        result.push(product);
        return result;
      }, []);

      this.update({ items: newItems });
    },

    /**
     * (오피스텔, 아파트)일반 상품 제거시 카트에 이미 추가되어있는 단지상품이 있을경우 제거한다.
     * @param {Number} idx 제거할 일반상품 위치
     * @param {Number} type 제거할 단지상품 타입
     */
    removeGeneral({ idx, type }, { purchase: { items } }) {
      let complexType = '';
      if (type === productType.GENERAL_OFFICETEL.value) {
        complexType = productType.OFFICETEL.value;
      } else if (type === productType.GENERAL_APT.value) {
        complexType = productType.APT.value;
      }

      const filtered = items.filter(d => d.type !== complexType);
      const newItems = produce(filtered, draft => {
        draft.splice(idx, 1);
      });

      const index = newItems.length - 1;
      if (index >= 0) {
        const i = idx - 1;
        scrollTo(i > -1 ? i : 0);
      } else {
        scrollToTop();
      }

      this.update({ items: newItems });
    },

    /**
     * 프리미엄 상품을 제거할때 사용
     * @param {Number} idx 삭제할 상품의 위치
     * @param {Array} types 프리미엄과 함께 제거될 상품 타입들
     */
    removePremium({ idx, types }, { purchase: { items } }) {
      let list = [...items];

      if (types.length > 0) {
        list = types.reduce((result, type) => result.filter(d => d.type !== type), list);
      }

      const newItems = produce(list, draft => {
        draft.splice(idx, 1);
      });

      const index = newItems.length - 1;
      if (index >= 0) {
        const i = idx - 1;
        scrollTo(i > -1 ? i : 0);
      } else {
        scrollToTop();
      }

      this.update({ items: newItems });
    },

    removeSpotShootingSeason(_, { purchase: { items } }) {
      const newItems = items.reduce((result, product) => {
        if (product.type === productType.SPOT_SHOOTING_SEASON.value) return result;
        result.push(product);
        return result;
      }, []);

      this.update({ items: newItems });
    },
  }),
});

export default purchase;
